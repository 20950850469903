import React from 'react'
import "./bloginner.scss";
import { rewardsBanner } from "../../assets";
import { Col, Container, Image, Row } from "react-bootstrap";
import bannerImg from '../../assets/2d-animation-course.jpg'

const Animation2D = () => {
    return (
        <>

            <div className="blog-single gray-bg">
                <Container>
                    <Row className="align-items-start">
                        <div className="top-banner-section">
                            <Image src={rewardsBanner} alt="" fluid className="height-80vh" />
                            <div className="reward-title" style={{ fontSize: "76px" }}> 2D Animation Course in Noida </div>
                        </div>
                        <Col sm={12} md={12} className="m-15 px-tb  pt-5">
                            <div className="article-img">
                                <Image
                                    src= {bannerImg}
                                    title=""
                                    alt=""
                                    width={"100%"}
                                />
                            </div>
                            <article className="article ps-4 mt-0">
                                <div className="article-title">
                                    <h3 className="text-white">
                                        Elevate Your Creativity with Animation Delhi NCR's 2D Animation Course in Noida
                                    </h3>
                                </div>
                                <div className="article-content">
                                    <p className="text-justify">
                                        Regarding skills in 2D animation, Animation Delhi NCR offers a unique opportunity and professionally trained animators in Noida.
                                    </p>
                                    <h3 className="text-white">2D animation course in Noida </h3>
                                    <p>
                                        The academy provides a highly integrated and structured 2d animation course in Noida that targets the current trend in the market. As for a student who is a beginner and who aims to become a professional 2D animator, this curriculum is thorough and will encompass all that would need to know from simple drawing to digital illustration and animation. The offered modules are character design, storyboarding, motion graphics, and special effects; thereby, the students get familiar with all the potential processes in animation.

                                    </p>
                                    <h3 className="text-white">Experienced and Passionate Faculty </h3>
                                    <p>
                                        Animation Delhi NCR is worth a team of highly experienced and dedicated instructors who possess immense teaching knowledge of the industry. Professors include professional animators with years of experience as well as those who are actively involved in practice having worked on some recognized projects in different media. The practical and custom individual approach makes it easier for students to improve their skills at the Faculty and create a portfolio. The outcomes of the courses indicate that practical knowledge given by the instructors is more effective than the knowledge received in the course of studies.

                                    </p>
                                    <h3 className="text-white">State-of-the-Art Facilities and Tools </h3>
                                    <p>
                                        In a field as competitive as animation, artists must have access to what is possible in terms of tools and technology. Animation Delhi NCR makes sure that its students are in sync with software and hardware tools utilized within the industry. Facilities available at the institute include up-to-date equipment like high-performance computers, graphic tablets, and software like Adobe Animate, Toon Boom Harmony, After Effects, etc. Such exposure to industry-standard tools provides students with the right entry to the professional world once they complete this course.
                                    </p>
                                    <h3 className="text-white">Focus on Practical Learning and Live Projects </h3>
                                    <p>
                                        The main distinguishing factor of the 2d animation course in Noida offered by Animation Delhi NCR is that they are focused on teaching practical skills. The subjects incorporate a large number of practice tasks and assignments that imitate working with real-life problems. Well, it is the case currently whereby students get the opportunity to work on live projects and to understand how a professional animation company or studio works and the various issues that practice entails. This forms a practical way of handling problems not only improving technical prowess but also sharpening imagination and problem-solving skills.
                                    </p>
                                    <h3 className="text-white">Strong Industry Connections and Placement Support</h3>
                                    <p>
                                        This directed Animation Delhi NCR to develop close relationships with major animation studios and media companies. They are used to ensure students are provided with enough internships and employment chances in various industries. The institute has a well-prominent placement cell that continues to help the students by placing them in various well-known companies. Additional facilities include organizing frequent workshops, seminars, and guest lectures conducted by eminent professionals in the industry that help in building relationships.
                                    </p>
                                    <p>

                                        Finally, Animation Delhi NCR is an institute that provides a 2D animation course in Noida which is well structured and designed according to the Industry demand at present. Having an all-rounded staff, exercise of modern equipment, emphasis on student practice, and a strong market attachment system, it is the right place for aspiring animation students to transform their creativity into reality.
                                    </p>  
                                </div>
                            </article>
                        </Col>
                    </Row>
                </Container>
            </div>




        </>
    )
}

export default Animation2D